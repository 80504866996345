import AppRouter from "./router/AppRouter";
import "./styles/styles.css";
import "./styles/queries.css";

function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
